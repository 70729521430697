export const firebaseConfig = {
  apiKey: "AIzaSyAMOu9wWKc00QJ9J3DmL2Pqii1q3ycza1o",
  authDomain: "visionbeyond-staging.firebaseapp.com",
  databaseURL: "https://visionbeyond-staging.firebaseio.com",
  projectId: "visionbeyond-staging",
  storageBucket: "visionbeyond-staging.appspot.com",
  messagingSenderId: "937790488005",
  appId: "1:937790488005:web:1f37e6ccb36b31854a5fac",
};

export const vapidKey = `BGHHolTr7FPEAe6GXar9tLu1URQWHbKeDnKTYeI9XgZi_aa_fqN5kB-8KjrHVIGJqEmAlGbkGGYBcHgm7dMwwsQ`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}