import {
  useState,
  useEffect
} from 'react';

// ...

export function useQuery(fetcher) {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function run() {
      const _data = await fetcher();
      
      setData(_data);
    }

    run();
  }, [fetcher])

  return data;
}