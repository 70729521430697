import React, {
  useRef,
  useEffect
} from 'react';

import PinField from 'react-pin-field';

import {
  makeStyles
} from '@material-ui/core/styles';

// ...

const useStyles = makeStyles(theme =>{
  return {

    pin: {
      width: 50,
      height: 50,
      marginLeft: 10,
      [theme.breakpoints.down("sm")]: {
        width: 40,
        height: 40,
        marginLeft: 5,
      },
      textAlign: 'center',
      outline: 'none',
      borderRadius: 5,
      border: '1px solid gray',
      transitionProperty: 'color, border, box-shadow, transform',
      transitionDuration: '250ms',

      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 7px rgba(0,255,0,0.5)',
        border: '1px solid blue',
        transform: 'scale(1.05)'
      }
    }
  }
});

// ...

export function PinCode({ setPin }) {
  const styles = useStyles();

  const fnRef = useRef();
  fnRef.current = {
    setPin
  };

  useEffect(() => {
    return () => fnRef.current.setPin('', false);
  }, []);

  return (
    <PinField
      className={styles.pin}
      length={6}
      validate="0123456789"
      onChange={code => {
        setPin(code, true);
      }}
    />
  )
}