import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  baseStyles
} from './baseStyles';

import {
  getAnnotationStrategy
} from './annotationStrategy';

// ...

const useStyles = makeStyles({
  ...baseStyles,
});


// ...

export function LiveAnnotation({ context, type, actions, color }) {
  const styles = useStyles();
  const [strategy, setStrategy] = useState(null);
  const canvasRef = useRef();

  const onHotspot = (annotationData) => {
    if (!annotationData) {
      return;
    }
    actions.onAnnotation(type, annotationData);
  }

  const onHotspotEnd = (annotationData) => {
    actions.onEndAnnotation(annotationData);
  }

  useEffect(() => {
    const strat = getAnnotationStrategy(type, canvasRef.current, color, context.isFullscreen, onHotspot, onHotspotEnd)
    setStrategy(strat);
  }, [type, color, context.isFullscreen]);

  // ...

  return (
    <div className={styles.container}>
      <canvas
        id="liveAnnotationCanvas"
        ref={canvasRef}
        className={styles.canvas}

        style={{
          position: 'absolute',
          left: context.videoAbsRect.x,
          top: context.videoAbsRect.y,
          width: context.videoAbsRect.width,
          height: context.videoAbsRect.height
        }}

        {...strategy?.getEventHandlers()}
      >
      </canvas>
    </div>
  )
}