import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select as MuiSelect,
} from '@material-ui/core';

// ...

function useLabelWidth() {
  const inputLabel = useRef(null);

  const [
    labelWidth,
    setLabelWidth
  ] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return [inputLabel, labelWidth];
}

// ...

export function Select({ name, value, onChange, label, children }) {
  const [
    labelRef,
    labelWidth
  ] = useLabelWidth();

  return (
    <FormControl
      required
      variant="outlined"
      fullWidth
    >
      <InputLabel
        ref={labelRef}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <MuiSelect
        value={value}
        onChange={onChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            id={name}
            name={name}
          />
        }
      >
        {children}
      </MuiSelect>
    </FormControl>
  )
}
