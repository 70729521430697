import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { baseStyles } from '../pages/baseStyles'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useAuth } from 'hooks'
import { deleteToken } from 'services/firebase'

// Implementation:
//     import InactivityDialog from '../components/InactivityDialog'  -- change file path
//     <InactivityDialog />  --  note: no longer requires props to be passed down

export function InactivityDialog() {
  const [open, setOpen] = useState(false) // state to open or close the alert
  const [timer, setTimer] = useState() // to keep track of intervalID
  const [content, setContent] = useState() // for countdown
  const { user, signOut } = useAuth()
  const timeoutTime = 14 * 60 * 1000 // changes how long until popup appears (ms)
  let sessionTimeoutID;

  const events = [ // events that will extend the session timeout
    'mousemove',
    'click',
    'keypress', 
    'touchstart'
  ];

  const sessionTimeout = () => {
    if (!!sessionTimeoutID)
    {
      clearTimeout(sessionTimeoutID);
    }
    sessionTimeoutID  = setTimeout(handleOpen, timeoutTime);  
  }

  // starts a 14 min timer when the page loads, after 14 min of no activity, the popup is opened via handleOpen()
  useEffect(() => {
    for (let e in events)
    {
      window.addEventListener(events[e], sessionTimeout);
    }
    sessionTimeout() // initiate session timeout in the case that the user doesn't create any events after switching to the page
    return () => {
        for (let e in events)
        {
          window.removeEventListener(events[e], sessionTimeout);
        }
        if (!!sessionTimeoutID)
        {
          clearTimeout(sessionTimeoutID); // clear countdown when navigating away from the page
        }
    }
  }, [])

  // open the alert - 60 seconds remaining until logout
  const handleOpen = () => {
    setOpen(true)
    setContent('You will be logged out in 60 seconds due to inactivity.')
    //timer function
    var timeRemaining = 60
    var logout_timer = setInterval(function () {
      setTimer(logout_timer)
      timeRemaining--
      setContent(
        'You will be logged out in ' +
          timeRemaining +
          ' seconds due to inactivity.',
      )

      if (timeRemaining < 1) {
        timeRemaining = 60
        clearInterval(logout_timer)
        handleInactiveLogOut()
      }
    }, 1000)
  }

  // 'Stay logged in' button within the alert
  const handleCloseStayLoggedIn = () => {
    setOpen(false)
    clearInterval(timer)
  }

  // 'log off' button within the alert
  const handleCloseLogOff = () => {
    setOpen(false)
    clearInterval(timer)
    signOut(() => deleteToken(user.uid))
  }

  // will run when the 60 seconds runs out -- to kick the user off
  const handleInactiveLogOut = () => {
    setOpen(false)
    signOut(() => deleteToken(user.uid))
  }

  //importing styles from material-ui for buttons
  const useStyles = makeStyles((theme) => ({
    button: {
      ...baseStyles.button,

      backgroundColor: theme.button.primary,
      marginBottom: '10px',
      marginRight: '10px',
    },

    buttonCancel: {
      ...baseStyles.button,

      backgroundColor: theme.button.secondary,
      marginRight: 10,
      marginBottom: '10px',
    },
  }))

  const styles = useStyles()

  return (
    <div>
      {/* <Button onClick={handleOpen}>Inactive test</Button> -- for dev purposes */}
      <Dialog
        open={open}
        // onClose={handleCloseStayLoggedIn}  -- this line allows the user to click away from the alert
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle>Still there?</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStayLoggedIn} className={styles.button}>
            Stay logged in
          </Button>
          <Button onClick={handleCloseLogOff} className={styles.buttonCancel}>
            Log out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}