import {
  useEffect,
  useMemo
} from 'react';

import {
  useNavigate,
  useLocation
} from 'react-router-dom'

import {
  useMachine
} from '@xstate/react';

import {
  useAuth,
  useNotify
} from 'hooks';

import {
  getAgoraService
} from 'services/agora';

import {
  getPushToken
} from 'services/firebase';

import {
  getToken
} from 'services/agora/token';

import {
  agoraMachine
} from 'state/agora';

import {
  agoraService
} from 'state/agoraService';

import {
  getActions
} from './getActions';

import {
  getView 
} from './getView';

// ...

export function Main( { history } ) {
  const {
    user,
    profile,
    signOut
  } = useAuth();

  const {
    notifyError
  } = useNotify();

  const {
    navigate
  } = useNavigate();

   const location = useLocation();

  // Listen for back button to leave call
  useEffect(() => {
    let unlisten = history.listen(({ _, action }) => {
      if( action === 'POP' && location.hash === 'call' ) {
        location.hash =''
        actions.onLeave();
      }
    });

      return () => {
        unlisten();
      };
  }, [])

  function navigateToCall() {
    if (location.hash !== 'call') {
      location.hash = 'call'
    }
    // Push to history to allow back button to work
    history.push('/')
  }

  function navigateFromCall() 
  {
    if( location.hash === 'call' ) {
      history.go(-1)
      location.hash = ''
    }
  }

  const [
    state,
    send
  ] = useMachine(agoraMachine, {
    services: {
      agoraService
    },

    actions: {
      callStateEntry : () => {
        navigateToCall()
      },
      idleStateEntry : () => {
        navigateFromCall()
      },
    },

    devTools: true
  });

  // Connect to RTM and request to show permissions

  useEffect(() => {
    const init = async () => {
      if ('Notification' in window) {
        Promise.resolve(Notification.requestPermission()).then(permission => {
          if (permission === 'granted') {
            getPushToken(user.uid);
          } else {
            notifyError('Unable to get permission to notify.');
          }
        });
      }

      // Try to get token for RTM and using it to connect
      try{
        const rtmToken = await getToken({
          type: 'rtm',
          uid: user.uid
        });

        getAgoraService().rtm.login(rtmToken, user.uid);
        // Add event listener which detects when the session is forcefully ended (connectionState becomes "ABORTED")
        getAgoraService().rtm.on('ConnectionStateChanged', function(newState, reason) {
          if(newState === 'ABORTED'){
            notifyError("Detected log-in on other device or in other browser tab; logging out")
            signOut();
          }
      
        });
      } catch (error) {
        notifyError("Error connecting to RTM, logging out");
        await signOut();
        navigate("/")
      }
    };
    

    init();

    // ...

    return () => {
      getAgoraService().rtm.logout();
    }
  }, [user.uid, send]);
  
  // ...

  const rtcUid = profile?.rtcUid;

  useEffect(() => {
    if (!rtcUid) {
      return;
    }

    getAgoraService().rtc.setUid(rtcUid);
  }, [rtcUid]);

    // ...

    const actions = useMemo(() => getActions(send), [send]);

    // ...

  return getView(state, actions);
}
