export const baseStyles = {
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  
  button: {
    padding: '10px 20px',
    color: 'white',
    borderRadius: 20
  }
};