import React, {
  useCallback
} from 'react';

import {
  Avatar,
  Button,
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  useQuery
} from 'hooks';

import {
  Audio
} from 'components';

import {
  fetchProfile
} from 'services/firebase';

import {
  baseStyles
} from '../../baseStyles';

import {
  getLayout
} from '../components/DefaultLayout';

import ringtone from 'assets/ringtone.mp3';

// ...

const useStyles = makeStyles(theme => {
  return {
    outer: {
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },

    inner: {
      width: '25%',
      [theme.breakpoints.down('sm')]: {
        width: '33%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '70%'
      },

      marginTop: '-64px',

      '& > *': {
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
          marginBottom: 25
        }
      }
    },

    avatar: {
      width: 100,
      height: 100,
      [theme.breakpoints.down('sm')]: {
        width: 75,
        height: 75
      },

      margin: '0 auto 25px auto'
    },

    buttonGroup: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    buttonRefuse: {
      ...baseStyles.button,

      backgroundColor: theme.button.secondary
      
    },

    buttonAccept: {
      ...baseStyles.button,

      backgroundColor: theme.button.primary
    },
  }
});

// ...

function Pending({ context, actions }) {
  const styles = useStyles();

  const {
    onAccept,
    onRefuse
  } = actions;

  // get host user's profile. 
  //
  // TBD: const { data, error } = useQuery(...);
  const hostProfile = useQuery(
    useCallback(() => fetchProfile(context.channel.host),
      [context.channel.host])
  );

  // ...

  if (!hostProfile) {
    return null;
  }

  return (
    <>
      <Audio src={ringtone} repeatCount={10} />

      <div className={styles.outer}>
        <div className={styles.inner}>
          <T align="center" variant="h5">Incoming call from </T>
          <div>
            <Avatar
              className={styles.avatar}
              src={hostProfile.image}
            />
            <T align="center" variant="h6">{hostProfile.first} {hostProfile.last}</T>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              className={styles.buttonRefuse}
              onClick={onRefuse}
            >
              Decline
          </Button>
            <Button
              className={styles.buttonAccept}
              onClick={onAccept}
            >
              Accept
          </Button>
          </div>
        </div>
      </div>
    </>
  )
}

Pending.getLayout = getLayout;

// ...

export {
  Pending
};