import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  baseStyles
} from './baseStyles';

import {
  ReactComponent as DiscardIcon
} from 'assets/annotation_cancel.svg';

import {
  ReactComponent as ConfirmIcon
} from 'assets/annotation_confirm.svg';

import {
  getAnnotationStrategy
} from './annotationStrategy';

// ...

const useStyles = makeStyles({
  ...baseStyles,

  controls: {
    height: 50,
    width: 125,

    position: 'fixed',
    bottom: 24,
    right: 24,

    display: 'flex',
    justifyContent: 'space-between'
  },

  button: {
    height: '100%',
    width: 'auto',
    cursor: 'pointer',
  }
});

// ...

function ScreenControls({ styles, onDiscard, onConfirm }) {
  return (
    <div className={styles.controls}>
      <DiscardIcon className={styles.button} onClick={onDiscard} />
      <ConfirmIcon className={styles.button} onClick={onConfirm} />
    </div>
  )
}

// ...

export function Annotation({ type, frame, actions, color }) {
  const styles = useStyles({ frame });

  const [strategy, setStrategy] = useState(null);

  const canvasRef = useRef();

  useEffect(() => {
    setStrategy(getAnnotationStrategy(type, canvasRef.current, color));
  }, [type, color]);

  // ...

  return (
    <div className={styles.container}>
      <ScreenControls
        styles={{
          controls: styles.controls,
          button: styles.button
        }}
        onDiscard={actions.onDiscard}
        onConfirm={() => {
          const annotationData = strategy.getAnnotationData();
          if (!annotationData) {
            // TBD: Notify user that an annotation must be drawn before sending it.
            return;
          }

          actions.onAnnotation(type, annotationData);
        }}
      />
      <canvas
        ref={canvasRef}
        className={styles.canvas}

        width={frame.width}
        height={frame.height}

        {...strategy?.getEventHandlers()}
      />
    </div>
  )
}