import React from 'react';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  Toolbar
} from './Toolbar';

import {
  baseStyles
} from './baseStyles';

import AppBackground from 'assets/web_app_bg.png';

// ...

const {
  fullscreen
} = baseStyles;

const useStyles = makeStyles({
  background: {
    ...fullscreen,

    zIndex: 1,

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  },

  overlay: {
    ...fullscreen,

    zIndex: 2
  },
});

// ...

function DefaultLayout({ children }) {
  const styles = useStyles();

  return (
    <>
      <div className={styles.background}>
        <img src={AppBackground} alt="" />
      </div>
      <div className={styles.overlay}>
        <Toolbar />
        {children}
      </div>
    </>
  )
}

// ...

export function getLayout(page) {
  return (
    <DefaultLayout>
      {page}
    </DefaultLayout>
  )
}