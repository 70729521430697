import {
  Hotspot as HOTSPOT,
} from './hotspot';

// ...

const strategies = {
  HOTSPOT
};

// ...

export function getAnnotationStrategy(type, canvas, color, isFullscreen, startCallback, endCallback) {
  return new strategies[type](canvas, color, isFullscreen, startCallback, endCallback);
}