import React from 'react';

import {
  Fab
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  ReactComponent as AnnotationsDisabledIcon
} from 'assets/ic_tools_disabled.svg';

import {
  ReactComponent as AnnotationsEnabledIcon
} from 'assets/annotation_tools.svg';

// ...

const useButtonStyles = makeStyles(theme => ({
  button: {
    width: 36,
    height: 36,
    boxShadow: 'none',
    pointerEvents: 'auto'
  }
}));

// ...

function Button(props) {
  const styles = useButtonStyles();

  return (
    <Fab classes={{ root: styles.button }} {...props}>
      { props.mode === 'enabled' ? <AnnotationsEnabledIcon /> : <AnnotationsDisabledIcon /> }
    </Fab>
  )
}

// ...

export function AnnotationButton({ disabled, mode, onEnable, onDisable}) {
  return (
    <Button
      disabled={disabled}
      mode={mode}
      onClick={mode === 'enabled' ? onDisable : onEnable}
    />
  )
}