export const baseStyles = {
  fullscreen: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: '0',
    top: '0'
  },
  
  /**
   * The container that holds the video when in windowed mode. It should always be fullscreen
   * with the desired background color.
   */
  windowedVideoContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(210, 210, 210)",
  },

};