import React from 'react';

import {
  NotifyContext
} from 'NotifyContext';

import {
  toast
} from 'react-toastify';

// Notify users of success with a toast.

function notifySuccess(content) {
  toast.success(content);
}

// Notify users of information with a toast.

function notifyInfo(content) {
  toast.info(content);
}

// Long notify users of information with a toast.

function longNotifyInfo(content) {
  toast.info(content, {
    autoClose: false
  });
}

// ...

function notifyWarning(content) {
  toast.warning(content);
}

// ...

function notifyError(content) {
  toast.error(content);
}

// this notify will not close automatically, preventing users from potentially missing warnings

function longNotifyError(content) {
  toast.error(content, {
    autoClose: false
  });
}

// ...

export class ErrorHandler extends React.Component {
  state = {
    error: null
  };

  // ...

  retry = () => {
    this.setState({
      error: null
    });
  }

  capture = error => {
    this.setState({
      error
    });
  }

  trace = (error, errorInfo) => {
    console.log(error, errorInfo);
  }

  // ...

  static getDerivedStateFromError(error) {
    return {
      error
    };
  }

  componentDidCatch(error, errorInfo) {
    this.trace(error, errorInfo);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.error && prevState.error === this.state.error) {
      this.retry();
    }
  }

  render() {
    const {
      error
    } = this.state;
    
    if (error) {
      const {
        fallback: Fallback
      } = this.props;
      
      if (Fallback) {
        return <Fallback error={error} retry={this.retry} />
      } else {
        // note: A root boundary MUST have a fallback set.
        throw error;
      }
    }

    return (
      <NotifyContext.Provider value={{
        capture: this.capture,
        trace: this.trace,
        notifyError,
        longNotifyError,
        notifyWarning,
        notifyInfo,
        longNotifyInfo,
        notifySuccess
      }}>
        {this.props.children}
      </NotifyContext.Provider>
    )
  }
}