import React from 'react';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  baseStyles
} from './baseStyles';

// ...

const useStyles = makeStyles(baseStyles);

// ...

export function Frame({ frame }) {
  const styles = useStyles({ frame });

  // ..

  return (
    <div className={styles.container}>
      <div 
        className={styles.canvas} 
        style={{
          width: frame.width,
          height: frame.height
        }}
      />
    </div>
  )
}