import React from 'react';

import {
  CircularProgress
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  baseStyles
} from '../../baseStyles';

import {
  getLayout
} from '../components/DefaultLayout';

// ...

const {
  center
} = baseStyles;

const useStyles = makeStyles({
  center
});

// ...

function Joining() {
  const styles = useStyles();

  return (
    <div className={styles.center}>
      <CircularProgress />
    </div>
  )
}

Joining.getLayout = getLayout;

// ...

export { 
  Joining 
};