/**
 * Normalizes a canvas point within the ranges -1 <= px <= 1, -1 <= py <= 1.
 * 
 * @param {number} px 
 * @param {number} py 
 * @param {number} width - The canvas width.
 * @param {number} height - The canvas height.
 */
export function normalizePoint(px, py, width, height) {
  const w = width / 2;
  const h = height / 2;

  const x = (px - w) / w;
  const y = -(py - h) / h;

  return { x, y };
}

export function loadImage(src) {
  return new Promise(resolve => {
    const image = new Image();

    image.src = src;
    image.onload = () => {
      resolve(image);
    };
  });
}

// ...

export function offsetFromTouchEvent(touchEvent) {

  let canvasRect = touchEvent.target.getBoundingClientRect();

  const touch 
    = (touchEvent.touches && touchEvent.touches[0]) || 
      (touchEvent.changedTouches && touchEvent.changedTouches[0]);
  
  return {
    offsetX: touch.clientX - touch.target.offsetLeft,
    offsetY: touch.clientY - touch.target.offsetTop,
    elementWidth: canvasRect.width,
    elementHeight: canvasRect.height
  };
  
}

export function offsetFromMouseEvent(mouseEvent) {
  
  let canvasRect = mouseEvent.srcElement.getBoundingClientRect();

  return {
    offsetX: mouseEvent.offsetX,
    offsetY: mouseEvent.offsetY,
    elementWidth: canvasRect.width,
    elementHeight: canvasRect.height
  };

}