import React from 'react';

import {
  Idle,
  Pending,
  Joining,
  Call
} from './views';

// ...

export function getView(state, actions) {
  let viewProps = {
    context: state.context,
    actions
  };

  let View;

  switch (true) {
    case state.matches({ connected: 'pending' }):
      View = Pending;
      break;

    case [
      'disconnected', 
      'connecting',
      { connected: 'idle' }
    ].some(state.matches):
      View = Idle;
      break;

    case state.matches({
      connected: {
        call: 'joining'
      }
    }):
      View = Joining;
      break;

    case state.matches({ 
      connected: { 
        call: 'joined'
      } 
    }):
      View = Call;

      viewProps = {
        ...viewProps,
        service: state.children.callMachine
      }
      
      break;

    default:
      return null;
  }

  // ...

  const getLayout = View.getLayout ?? (page => page);
  
  return getLayout(<View {...viewProps} />);
}