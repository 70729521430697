import {
  useRef
} from 'react';

// ...

export function useValue(value) {
  const valueRef = useRef();

  if (value && valueRef.current !== value) {
    valueRef.current = value;
  }

  return valueRef.current;
}