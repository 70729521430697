import React from "react";

import {
  BrowserRouter as Router,
  Navigate
} from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';

import {
  ToastContainer,
  Slide
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import {
  ErrorHandler
} from 'components';

import {
  AuthenticationProvider,
  ThemeProvider
} from 'providers';

import AppRoutes from './AppRoutes';

import {
  firebaseConfig
} from 'firebaseConfig';

// ...
// Firebase configuration.
firebase.initializeApp(firebaseConfig);

if (firebase.messaging.isSupported()) {
  firebase.messaging().onMessage((payload) => {
    console.log('Message received. ', payload);
  });
}

// ...

function App() {
  return (
    <Router>
      <ToastContainer
        position="bottom-center"
        transition={Slide}
        theme="colored"
        hideProgressBar
      />
      <ErrorHandler fallback={({ error }) => <Navigate to="/error" state={{ error }} />}>
        <ThemeProvider>
          <AuthenticationProvider>
            <AppRoutes />
          </AuthenticationProvider>
        </ThemeProvider>
      </ErrorHandler>
    </Router>
  );
}

export default App;
